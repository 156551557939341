.body {
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
}

.hero_banner {
  scroll-snap-align: start;
}

.nav-menu li {
  text-transform: capitalize;
}

.footer-menu li {
  text-transform: capitalize;
}

.cursor-pointer {
  cursor: pointer !important;
}

div::-webkit-scrollbar {
  display: none;
}

ul::-webkit-scrollbar {
  display: none;
}

div,
ul {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
